import axios from 'axios'
import common from '@/api/common.js'

const api = axios.create()

export default {
  getAlarmsForSite: function (skip, take, site) {
    console.log('getAlarmsForSite()', skip, take, site)
    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/alarm/get/${site}`,
      data: {
        skip,
        take,
      },
    })
  },
  getAlarmsForSiteByDate: function (dateObject) {
    console.log('getAlarmsForSiteByDate()', dateObject)
    const headers = common.createHeaders()

    return api({
      method: 'post',
      headers: headers,
      url: `${common.getUrlPrefix()}/alarm/getByDate/${dateObject.rootSiteId}`,
      data: {
        startDate: dateObject.startDate,
        endDate: dateObject.endDate,
      },
    })
  },
}
