<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <v-toolbar
          style="position: relative"
          dense
          justify="end"
        >
          <v-spacer />
          <v-tooltip
            v-if="historical"
            bottom
            open-delay="1000"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey darken-1"
                text
                dark
                v-bind="attrs"
                v-on="on"
                @click="returnToCurrentAlarms"
              >
                <v-icon>
                  mdi-keyboard-return
                </v-icon>
              </v-btn>
            </template>
            <span>Return To Current Alarm Data</span>
          </v-tooltip>
          <v-tooltip
            bottom
            open-delay="1000"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="props">
                    <v-btn
                      color="primary"
                      text
                      dark
                      v-bind="props.attrs"
                      v-on="props.on"
                    >
                      <v-icon>
                        mdi-calendar-search
                      </v-icon>
                    </v-btn>
                  </template>
                  <div
                    class="d-flex justify-center flex-wrap pb-7"
                    style="width: 290px; background: white; padding-bottom: 5px;"
                  >
                    <v-date-picker
                      v-model="dates"
                      range
                    />
                    <v-btn
                      color="green darken-1 mb-4"
                      text
                      :loading="loadingHistoricData"
                      :disabled="dates.length === 0"
                      @click="setAlarmHistoricData"
                    >
                      Show data for selected date(s)
                    </v-btn>
                  </div>
                </v-menu>
              </div>
            </template>
            <span>View Historical Data</span>
          </v-tooltip>
          <v-tooltip
            bottom
            open-delay="1000"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                text
                dark
                v-bind="attrs"
                v-on="on"
                @click="openDownloadDialog"
              >
                <v-icon>
                  mdi-download
                </v-icon>
              </v-btn>
            </template>
            <span>Download CSV</span>
          </v-tooltip>
        </v-toolbar>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        class="py-0"
      >
        <v-alert
          v-if="showStatusMessage"
          :type="statusMessageType"
          width="100%"
        >
          {{ statusMessage }}
        </v-alert>
      </v-col>
      <v-col
        cols="12"
      >
        <v-card>
          <v-container class="d-flex justify-start flex-wrap pb-0">
            <v-card-title
              class="text-h3 font-weight-bold grey--text justify-start"
              style="width: 100%;"
            >
              Alarms
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="alarmsArray"
              :search="search"
              :hide-default-footer="true"
              :height="`${alarmsArray.length === 0 ? '200px': '650px'}`"
              :fixed-header="true"
              :items-per-page="-1"
              :loading="loadingAlarms"
            >
              <template v-slot:[`body.prepend`]>
                <tr>
                  <td>
                    <v-text-field
                      v-model="time"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                      label="filter..."
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="severity"
                      type="number"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="equipmentName"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="alarmDescription"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="dataValue"
                      type="number"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="alarmStatus"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                </tr>
              </template>
              <template
                v-slot:footer
              >
                <div class="d-flex align-center">
                  <span v-if="!historical">
                    Rows per page:
                  </span>
                  <div
                    v-if="!historical"
                    style="width: 50px; margin-left: 30px;"
                  >
                    <v-select
                      v-model="take"
                      :items="rowAmounts"
                      style="width: 100%;"
                      @change="onChangeRowSelection"
                    >
                      <template v-slot:selection="{item}">
                        <span style="font-size: 14px;">
                          {{ item }}
                        </span>
                      </template>
                    </v-select>
                  </div>
                  <span
                    v-if="alarmsArray.length > 0"
                    style="margin-left: 50px; margin-right: 20px; margin-top: 23px; margin-bottom: 23px;"
                  >
                    {{ `Showing ${rowRangeStart}-${rowRangeEnd} of ${totalAlarms}` }}
                  </span>

                  <v-btn
                    v-if="!historical"
                    color="grey"
                    text
                    small
                    :disabled="rowRangeStart === 1"
                    @click="pageDown"
                  >
                    <v-icon>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!historical"
                    color="grey"
                    text
                    small
                    :disabled="rowRangeEnd >= totalAlarms"
                    @click="pageUp"
                  >
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDownloadDialog"
      persistent
      max-width="400"
    >
      <v-card class="pa-4 px-6">
        <v-card-title class="text-h5 pl-0">
          Download CSV
        </v-card-title>
        <v-text-field
          v-model="filename"
          label="Filename"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="downloadCSV"
          >
            Save
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            @click="showDownloadDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      max-width="400"
    >
      <v-card class="pa-4 px-6">
        <v-card-title class="text-h5 pl-0">
          Error:
        </v-card-title>
        <span> {{ errorDialogMessage }} </span>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import alarmsApi from '@/api/alarms.js'
  import { convertAlarmsToCSV } from '@/util/converters'
  import common from '@/api/common.js'

  export default {
    name: 'Alarms',
    data: () => ({
      search: '',
      time: '',
      severity: '',
      equipmentName: '',
      alarmDescription: '',
      dataValue: '',
      alarmStatus: '',
      alarmsArray: [],
      loadingAlarms: true,

      showStatusMessage: false,
      statusMessage: '',
      statusMessageType: 'info',
      statusMessageNoAlarms: 'There are currently no alarms for your site',
      statusMessageApiError: 'We are unable to access your site\'s Alarms at this time. Please try again later.',
      statusMessageNoAlarmHistory: 'There is no alarm history in that date range.',

      pageNumber: 1,
      rowRangeStart: 1,
      rowRangeEnd: 10,
      totalAlarms: 0,
      take: 10,
      rowAmounts: [10, 20, 30],

      filename: 'alarms.csv',
      showDownloadDialog: false,

      showErrorDialog: false,
      errorDialogMessage: '',
      errorDialogMessageNoData: 'There is currently no data to export.',

      dateMenu: false,
      dates: [],
      loadingHistoricData: false,
      historical: false,

    }),
    computed: {
      headers () {
        return [
          {
            text: 'Time',
            align: 'left',
            value: 'timestamp',
            filter: value => {
              if (!this.time) return true
              return (value + '').toLowerCase().includes(this.time.toLowerCase())
            },
          },
          {
            text: 'Severity',
            value: 'severity',
            filter: value => {
              if (!this.severity) return true
              return value === parseInt(this.severity)
            },
          },
          {
            text: 'Equipment Name',
            value: 'equipmentName',
            filter: value => {
              if (!this.equipmentName) return true
              return (value + '').toLowerCase().includes(this.equipmentName.toLowerCase())
            },
          },
          {
            text: 'Alarm Description',
            value: 'alarmDescription',
            filter: value => {
              if (!this.alarmDescription) return true
              return (value + '').toLowerCase().includes(this.alarmDescription.toLowerCase())
            },
          },
          {
            text: 'Data Value',
            value: 'dataValue',
            filter: value => {
              if (!this.dataValue) return true
              return value === this.dataValue
            },
          },
          {
            text: 'Alarm Status',
            value: 'alarmStatus',
            filter: value => {
              if (!this.alarmStatus) return true
              return (value + '').toLowerCase().includes(this.alarmStatus.toLowerCase())
            },
          },
        ]
      },
    },
    created () {
      this.getAlarms()
    },
    methods: {
      getAlarms: function () {
        this.loadingAlarms = true
        this.showStatusMessage = false
        let skipFinallyBlock = false

        alarmsApi.getAlarmsForSite(this.rowRangeStart - 1, this.take, this.$route.params.id)
          .then(response => {
            console.log(response)
            this.totalAlarms = response.data.numberOfAlarms
            this.alarmsArray = response.data.alarmList
            if (this.alarmsArray.length === 0) {
              this.showStatusMessage = true
              this.statusMessageType = 'info'
              this.statusMessage = this.statusMessageNoAlarms
            }
            if (this.totalAlarms < this.rowRangeEnd) {
              this.rowRangeEnd = this.totalAlarms
            }
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.getAlarms()
            } else {
              this.showStatusMessage = true
              this.statusMessageType = 'error'
              this.statusMessage = this.statusMessageApiError
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.loadingAlarms = false
            }
          })
      },
      pageUp: function () {
        this.rowRangeStart = this.rowRangeEnd + 1
        if (this.rowRangeEnd + this.take >= this.totalAlarms) {
          this.rowRangeEnd = this.totalAlarms
        } else {
          this.rowRangeEnd += this.take
        }
        this.getAlarms()
      },
      pageDown: function () {
        if (this.rowRangeStart <= this.take) {
          this.rowRangeStart = 1
        } else {
          this.rowRangeStart -= this.take
        }
        if (this.rowRangeStart - 1 + this.take >= this.totalAlarms) {
          this.rowRangeEnd = this.totalAlarms
        } else {
          this.rowRangeEnd = this.rowRangeStart - 1 + this.take
        }
        this.getAlarms()
      },
      onChangeRowSelection: function () {
        if (this.rowRangeStart - 1 + this.take >= this.totalAlarms) {
          this.rowRangeEnd = this.totalAlarms
        } else {
          this.rowRangeEnd = this.rowRangeStart - 1 + this.take
        }
        this.getAlarms()
      },
      openDownloadDialog: function () {
        if (this.alarmsArray.length === 0) {
          this.showErrorDialog = true
          this.errorDialogMessage = this.errorDialogMessageNoData
        } else {
          this.showDownloadDialog = true
        }
      },
      downloadCSV: function () {
        const csv = convertAlarmsToCSV(this.alarmsArray)
        const encodedData = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        const link = document.createElement('a')

        link.setAttribute('href', encodedData)
        link.setAttribute('download', `${this.filename}`)
        link.click()
        this.showDownloadDialog = false
      },
      setAlarmHistoricData: function () {
        let dateObject = {}
        this.loadingHistoricData = true
        this.showStatusMessage = false
        let skipFinallyBlock = false

        // TODO add functionality for more specific time instead of hardcoding 0's
        if (this.dates.length === 1 || this.dates[0] === this.dates[1]) {
          dateObject = {
            startDate: this.dates[0] + 'T00:00:00.000Z',
            endDate: null,
          }
        } else {
          if (this.dates[0].split('-').join() > this.dates[1].split('-').join()) {
            dateObject = {
              startDate: this.dates[1] + 'T00:00:00.000Z',
              endDate: this.dates[0] + 'T00:00:00.000Z',
            }
          } else {
            dateObject = {
              startDate: this.dates[0] + 'T00:00:00.000Z',
              endDate: this.dates[1] + 'T00:00:00.000Z',
            }
          }
        }

        dateObject.rootSiteId = parseInt(this.$route.params.id)
        alarmsApi
          .getAlarmsForSiteByDate(dateObject)
          .then(response => {
            console.log(response)

            if (response.data.length === 0) {
              this.showStatusMessage = true
              this.statusMessageType = 'info'
              this.statusMessage = this.statusMessageNoAlarmHistory
              this.rowRangeStart = 0
              this.rowRangeEnd = 0
              this.totalAlarms = 0
              this.alarmsArray = []
            } else {
              this.alarmsArray = response.data.alarmList
              this.totalAlarms = this.alarmsArray.length
              this.rowRangeStart = 1
              this.rowRangeEnd = this.totalAlarms
            }
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.setAlarmHistoricData()
            } else {
              this.showStatusMessage = true
              this.statusMessageType = 'error'
              this.statusMessage = this.statusMessageApiError
              this.alarmsArray = []
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.dateMenu = false
              this.loadingHistoricData = false
              this.historical = true
            }
          })
      },
      returnToCurrentAlarms: function () {
        this.historical = false
        this.rowRangeEnd = this.rowRangeStart - 1 + this.take
        this.getAlarms()
      },
    },
  }
</script>
